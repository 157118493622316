.center-content {
    display: flex;
    justify-content: center;
}

.margin40 {
    margin: 40px
}

.width {
    width: 13.5%;
}

.tab-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 200%;
}
