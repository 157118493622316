.on-call {
    display: flex;
    justify-content: space-evenly;
    margin: 30px;
    margin-top: 70px;
}

.planned-workers {
    border: 3px solid #e0e0e0;
    padding: 20px;
    margin: 30px;
    width: 20%;
    border-radius: 20px
}

.planned-workers-text {
    width: 60%;
    border-bottom: 2px solid #e39865;
}

.actual-workers {
    width: 40%;
}

.shifts {
    width: 10%;
}

.percentage {
    color: #e39865;
    margin-right: 80px;
    font-size: 35
}

.worker-stats {
    display: flex;
    justify-content: space-between
}

.table {
    align-items: center;
    margin-left: 12%;
    width: 76%
}