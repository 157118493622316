.modal {
    padding: 20px;
    position: absolute;
    width: 600px;
    top: 7%;
    height: 980px;
    background-color: rgb(255, 255, 255);
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    z-index: 100000;
}
