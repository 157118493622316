.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: white;
}

.link {
    padding: 2px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rt {
    margin: 0 10px;
    padding: 7px;
    text-decoration: none !important;
    font-weight: 500;
    text-align: center;
    color: #111010 !important;
}

.dropdown {
    text-decoration: none !important;
    font-weight: 500;
    text-align: center;
    color: #111010 !important;
}

.rt:hover {
    cursor: pointer;
    border-bottom: 4px solid #fff;
}

.focused {
    border-bottom: 2px solid #e39865;
}
