.main-header {
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    position: sticky;
    top: 0;
    background-color: white;
    display: flex;
    z-index: 1000;
    color: black;
    box-shadow: 0px 4px 10px rgba(134, 134, 134, 0.11); 
}

.logo {
    font-size: 27px;
    font-weight: 500;
    text-decoration: none;
    color: black;
}
