.filter-div {
    display: flex;
    margin: 20px;
    padding: 10px;
    border: 1px solid lightgray;
}

.main-map-div {
    display: flex;
    margin: 20px;
}

.map-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-workers {
    min-width: 350px;
    max-height: 74vh;
    border: 1px solid lightgray;
    overflow-y: auto;
}

.container-2 {
    width: 80%;
    margin: 20px auto;
}
  
.workers-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.total {
    font-weight: normal;
    color: #f28434;
}

.workers-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.worker-card {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.worker-card:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.worker-name {
    font-size: 1.2rem;
    margin: 0;
    color: #2c3e50;
}

.worker-detail {
    margin: 5px 0;
    font-size: 0.95rem;
}

.worker-details-link {
    color: #f28434;
    text-decoration: none;
    font-weight: bold;
}
 
.worker-details-link:hover {
   text-decoration: underline;
}

@media (max-width: 1200px) {
    .filter-div {
        flex-direction: column;
    }

    .main-map-div {
        flex-direction: column;
    }

    .list-workers {
        height: 50vh;
    }

    .worker-card {
        padding: 8px;
    }

    .worker-name {
        font-size: 1rem;
    }

    .worker-detail {
        font-size: 0.85rem;
    }
}

@media (max-width: 480px) {

    .worker-name {
        font-size: 0.9rem;
    }

    .worker-detail {
        font-size: 0.8rem;
    }
}
