.add-button {
    display: flex;
    justify-content: right;
    margin: 20px;
}

.total-clients {
    border-width: 2px;
    height: 230px;
    width: 450px;
    border-radius: 20px;
    border: 2px solid rgb(230, 229, 229)
}
