.charts {
    display: flex;
    margin: 10px
}

.pie-chart {
    width: 650px;
    height: 550px;
    display: flex;
    background-color: #ebebeb;
    padding: 15px;
    border-right: 10px solid white;
}

.line-chart {
    width: 1080px;
    height: 550px;
    background-color: #ebebeb;
    padding: 15px;
}

.column-chart {
    width: 1080px;
    height: 550px;
    background-color: #ebebeb;
    padding: 15px;
    border-right: 10px solid white;
}

.pie-chart-status {
    width: 650px;
    height: 550px;
    background-color: #ebebeb;
    padding: 15px;
}