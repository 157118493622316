.container-map {
    width: 400px;
    height: 610px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.147);
}
  
.title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}
  
.subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}
 
.region-list {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
}
  
.region-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease;
}
  
.region-item:hover {
    background-color: #f5f5f5;
}
  
.region-name {
    font-size: 16px;
    color: #ff6600;
}
  
.region-workers {
    font-size: 16px;
    color: #333;
}

@media (max-width: 1500px) {
    img {
        width: 700px;
    }
}

@media (max-width: 1100px) {
    img {
        display: none;
    }
}

@media (max-width: 500px) {
    .container-map {
        width: 350px;
        margin: 0;
        height: 610px;
        padding: 20px;
        border-radius: none;
        box-shadow: none;
    }    
}
