.location-container {
    margin: 200px;
    margin-top: 10px;
}

.header-logger {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20;
    border-bottom: 1px solid lightgray
}

.left-align {
    text-align: left;
}

.location-logger-table {
    width: 100%;
    margin: 20px;
    font-size: 18px;
    font-family: Consolas;
}

.location-logger-table th, .location-logger-table td {
    height: 20px;
    padding: 5px;
}

.location-logger-table th {
    background-color: white;
    font-size: large;
}

.location-logger-table td {
    font-size: medium;
    text-align: center;
}

.uk-time {
    margin-top: 2px;
    font-size: medium;
    font-weight: 400;
}

.border-right {
    border-right: 2px solid #ccc;
}

.pagination-div {
    display: flex;
    justify-content: right;
    margin-top: 50px;
}
