.map {
    height: 92vh;
    width: 100%
}

.modal-map {
    padding: 20px;
    position: absolute;
    width: 400px;
    top: 7.5%;
    height: 880px;
    background-color: rgb(255, 255, 255);
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

.line {
    height: 2px;
    background-color: #ebebeb;
    margin-top: 10px;
}

.marginBottom {
    margin-bottom: 10px;
}

.details {
    display: flex;
    align-items: center;
    padding: 1px;
    margin-bottom: 3px;
    margin-top: 30px;
}

.text {
    font-weight: bold;
}

.open-button {
    z-index: 10000;
    top: 80px;
    background-color: #fff;
    border: 2px solid #aeb2ab;
    color: #000;
    margin-left: 10px;
    width: 10px;
    border-radius: 4px;
}
