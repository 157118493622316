.container {
    display: flex;
    flex-direction: row;
    inset: 0;
    position: fixed;
    box-shadow: 0 0 10px rgb(0, 0, 0);
    background-color: #fdf0d6;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 60vh;
    height: 70vh;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 10px rgb(207, 207, 207);
}

h2 {
    color:rgb(31, 31, 31)
}

.background-image {
    background-image: url('../../assets/map.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 95vh;
    width: 65vh;
    margin: 70px;
}

.button-mui {
    width: 70%;
    background-color: #8d3902;
    color: white;
}

.button-mui:hover {
    background-color: white;
}

.forgot-password {
    text-align: end !important;
}

@media (max-width: 1200px) {
    .background-image {
        display: none;
    }
}
@media (max-width: 560px) {
    .login-form {
        border-radius: 0px;
    }
}
