.geofence-modal {
    padding: 20px;
    position: absolute;
    width: 1050px;
    height: 600px;
    top: 16%;
    left: 21%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.open-button {
    z-index: 10000;
    top: 162px;
    background-color: #fff;
    border: 2px solid #aeb2ab;
    color: #000;
    margin-left: 10px;
    width: 10px;
    border-radius: 4px;
}

.modal-live {
    padding: 20px;
    position: absolute;
    width: 400px;
    top: 7.5%;
    right: 0;
    height: 880px;
    background-color: rgb(255, 255, 255);
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    background: white;
}

.pulse {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #08AFA8;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 3s linear infinite;
}

.pulse:hover {
  animation: none;
}

.modal-header {
    padding: 10px;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(134, 134, 134, 0.197);
}

.close-modal-btn {
    background: none;
    border: none;
    font-size: 26px;
    color: #000;
    cursor: pointer;
}

.modal-body {
    padding: 20px;
    overflow-y: auto;
}

.worker-details {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.worker-details:hover {
    cursor: pointer;
    background-color: #dddddd41;
}

.worker-details p {
    margin: 5px 0;
}

.geofence-identifier {
    background-color: white;
    font-weight: 600;
    text-align: center;
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
        box-shadow: 0 0 0 50px rgba(255, 109, 74, 0), 0 0 0 0 #0ac3ba;
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 50px rgba(255, 109, 74, 0), 0 0 0 30px rgba(255, 109, 74, 0);    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 30px rgba(255, 109, 74, 0);
    }
}
