.geofence-form {
    margin: 0 auto;
    width: 400px;
}

.geofence-h2 {
    text-align: center;
    margin-bottom: 50;
    margin-top: 50;
}

.geofence-button {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
