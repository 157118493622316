.main-div {
    display: flex;
    margin: 10px
}

.regions-div {
    margin: 4px;
    width: 50%;
    height: 50%;
    padding: 15px;
}

.header-div {
    display: flex;
    justify-content: right;
    margin: 15px;
}

.header-div-title {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
}

@media (max-width: 900px) {
    .main-div {
        flex-direction: column;
    }

    .regions-div {
        width: 90%;
        height: 90%;
    }    
}

@media (max-width: 600px) {
    .main-div {
        flex-direction: column;
    }

    .regions-div {
        width: 85%;
        height: 85%;
    }

    .header-div-title {
        display: none;
    }
}
